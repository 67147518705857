import { createStore } from 'vuex'
import axios from "axios";
import api from "./api";
import router from '../router'

export default createStore({
  state: {
    isAdmin: !!localStorage.getItem('isAdmin'),
    invoices: [],
    clients: [],
    client: {
      contact: '',
      invoices: []
    },
    hidePaid: false,
    searchString: '',
    invoice: {file: undefined, createdAt: undefined, paid: false, price: 0, name: '', client_id: '', details: '', partial_pay: 0}
  },
  mutations: {
    SET_INVOICES (state, list) {
      state.invoices = list
    },
    SET_IS_ADMIN (state, value) {
      state.isAdmin = value
    },
    SET_FILTER (state, {name, value}) {
      state[name] = value
    },
    SET_CLIENTS (state, data) {
      state.clients = data
    },
    SET_CLIENT (state, data) {
      state.client = data
    },
    SET_INVOICE (state, doc) {
      state.invoice = doc
    },
    ADD_CLIENT (state, client) {
      state.clients.push(client)
    },
    UPDATE_CLIENT (state, details) {
      Object.keys(details).forEach(key => {
        state.client[key] = details[key]
      })
    },
    UPDATE_INVOICES (state, entry) {
      state.invoices.push(entry)
    }
  },
  actions: {
    async get_list ({commit}) {
      try {
        const { data } = await axios.get(api.invoices)
        commit('SET_INVOICES', data)
      } catch (err) {
        console.log(err)
      }
    },
    async get_invoice ({commit}, id) {
      try {
        const { data } = await axios.get(`${api.invoice}/${id}`)
        commit('SET_INVOICE', data)
      } catch (err) {
        console.log(err)
      }
    },
    async update_invoice ({commit}, {id, formData}) {
      try {
        const { data } = await axios.put(`${api.invoice}/${id}`, formData)
        // commit('SET_INVOICE', data)
        await router.push('/invoices')
      } catch (err) {
        console.log(err)
      }
    },
    async save_invoice ({commit}, formData) {
      try {
        const { data } = await axios.post(api.invoices, formData)
        commit('UPDATE_INVOICES', data)
        await router.push('/invoices')
      } catch (err) {
        console.log(err)
      }
    },
    async get_clients ({commit}) {
      try {
        const { data } = await axios.get(api.clients)
        commit('SET_CLIENTS', data)
      } catch (err) {
        console.log(err)
      }
    },
    async get_client ({commit}, id) {
      try {
        commit('SET_CLIENT', {invoices: []})
        const { data } = await axios.get(`${api.client}/${id}`)
        commit('SET_CLIENT', data)
      } catch (err) {
        console.log(err)
      }
    },
    async save_client ({commit}, client) {
      try {
        const { data } = await axios.post(api.client, client)
        commit('ADD_CLIENT', data)
        await router.push('/clients')
      } catch (err) {
        console.log(err)
      }
    },
    async update_client ({commit}, details) {
      try {
        const id = router.currentRoute.value.params.id
        const { data } = await axios.put(`${api.client}/${id}`, details)
        commit('UPDATE_CLIENT', details)
      } catch (err) {
        console.log(err)
      }
    },
    async pay_client ({commit, dispatch}, amount) {
      try {
        const id = router.currentRoute.value.params.id
        const { data } = await axios.put(`${api.payClient}/${id}`, {amount})
        dispatch('get_client', id)
      } catch (err) {
        console.log(err)
      }
    },
  },
  getters: {
    clientTotalUnpaidInvoices: state => {
      return state.client?.invoices
        .filter(o => !o.paid)
        .reduce((a, c) => +c.price - +c.partial_pay + a, 0)
    },
    invoices: state => {
      let results = state.invoices
      if (!!state.searchString) {
        results = state.invoices.filter(o => o.client.toLowerCase().includes(state.searchString))
      }
      if (state.hidePaid) {
        results = state.invoices.filter(o => !o.paid)
      }
      return results
    },
    clients: state => {
      let results = state.clients
      if (!!state.searchString) {
        results = state.clients.filter(o => o.name.toLowerCase().includes(state.searchString))
      }
      return results
    }
  },
  modules: {
  }
})
