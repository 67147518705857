export default {
  login: '/api/auth/login',
  logout: '/api/auth/logout',
  checkLogin: '/api/auth/login',
  invoices: '/api/invoices',
  invoice: '/api/invoice',
  client: '/api/client',
  payClient: '/api/client-pay',
  clients: '/api/clients',
};
