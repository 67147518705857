import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/invoices',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('../views/InvoicesView.vue')
  },
  {
    path: '/new',
    name: 'new-entry',
    component: () => import('../views/NewInvoiceView.vue')
  },
  {
    path: '/edit-invoice/:id',
    name: 'edit-invoice',
    component: () => import('../views/EditInvoiceView.vue')
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('../views/ClientsView.vue')
  },
  {
    path: '/add-client',
    name: 'Add-Client',
    component: () => import('../views/AddClientView.vue')
  },
  {
    path: '/client/:id',
    name: 'client',
    component: () => import('../views/ClientView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
